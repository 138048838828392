const theme = {
  colors: {
    main: `#1d1d1d`,
    lighter: `#767676`,
    revert: `#FFF`,
    primary: `#003cff`,
    primary17: `#c0cfff`,
    border: `#F6F6F6`,
    contrast: `#767676`,
  },
  bg: {
    revert: `#000`,
    main: `#FFF`,
  },
  border: {
    primary: `#434343`,
  },
  fonts: {
    main: `Suisse-regular, 'Helvetica Neue',Helvetica,Arial,sans-serif`,
    title: `'Telegraph', 'Helvetica Neue',Helvetica,Arial,sans-serif`,
    light: `'Suisse-light', 'Helvetica Neue',Helvetica,Arial,sans-serif`,
  },
  maxWidth: `1150px`,
  contentWidth: `760px`,
  mediaQueries: {
    desktopFlorent: `@media(max-width:1536px)`,
    tabletLandscape: `@media(max-width:1150px)`,
    mobile: `@media(max-width:760px)`,
    miniMobile: `@media(max-width:374px)`,
  },
};

export type ThemeCustom = typeof theme;

export default theme;
