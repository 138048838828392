import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import GlobalStyles from './src/global/styles/GlobalStyles';
import theme from './src/global/styles/theme';
import { ThemeProvider } from '@emotion/react';

export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};
